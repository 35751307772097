<template>
  <div>
    <app-cells position="start">
      <app-button @click="onAddButtonHandler" type="button" size="link">
        <img src="@/assets/img/add-icon.svg" alt="">
        Добавить стол
      </app-button>
    </app-cells>
    <app-cells position="between">
      <app-cells position="start" :indent="false">
        <app-filters
          v-model="filterHall"
          :filters="filtersHalls"
          name="filterHall"
          @change.native="fetchItems('', filterActive, filterHall)"
        />
        <app-filters
          v-model="filterActive"
          :filters="filtersActive"
          name="filterActive"
          @change.native="fetchItems('', filterActive, filterHall)"
        />
      </app-cells>
      <app-input
        v-model="input_search"
        @input.native="onFilterSearch($event.target.value)"
        class="input--450"
        type="search"
        placeholder="Поиск по номеру стола"
      />
    </app-cells>
    <v-client-table
      @row-click="onRowClick"
      :data="table.items"
      :columns="table.columns"
      :options="table.options"
      ref="tableDefault"
      class="table-default table-default--dynamic"
    >
      <div slot="h__hall.name" class="table-default__left">Зал</div>
      <div slot="hall.name" slot-scope="props" class="table-default__left">
        {{ props.row.hall.name }}
      </div>
      <div slot="min_seat_number" slot-scope="props">
        {{ props.row.min_seat_number === props.row.max_seat_number ? props.row.min_seat_number : `${props.row.min_seat_number} - ${props.row.max_seat_number}` }}
      </div>
    </v-client-table>
    <app-sidebar-right
      :title="!isAdd ? 'Редактирование стола' : 'Добавление стола'"
      :class="{'sidebar-manage--full': show_sidebar}" @close-sidebar="show_sidebar = !show_sidebar"
    >
      <form @submit.prevent="onCheckForm">
        <app-form-group label="Зал" required>
          <v-select
            v-model="sidebarData.hall"
            :reduce="item => item.id"
            :options="hallsList"
            :filterable="false"
            :clearable="false"
            :searchable="false"
            label="name"
            placeholder="Выберите зал"
            class="select"
            :class="{ 'select--error': $v.sidebarData.hall.$error }"
          >
            <template #open-indicator>
              <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                <path
                  d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                  fill="#fff"></path>
              </svg>
            </template>
            <template #option="option">
              <div class="select__item d-center">{{ option.name }}</div>
            </template>
            <template #selected-option="option">
              <div class="selected d-center">{{ option.name }}</div>
            </template>
          </v-select>
          <template #error>
            <div v-if="$v.sidebarData.hall.$dirty && !$v.sidebarData.hall.required">Обязательное поле</div>
          </template>
        </app-form-group>
        <app-form-group label="Номер стола" label-for="number" required>
          <app-input
            v-model="sidebarData.number"
            placeholder="Введите номер стола"
            id="number"
            :error="$v.sidebarData.number.$error"
          />
          <template #error>
            <div v-if="$v.sidebarData.number.$dirty && !$v.sidebarData.number.required">Обязательное поле</div>
          </template>
        </app-form-group>
        <app-form-group label="Номер стола в R keeper" label-for="uuid_rkeeper" required>
          <app-input
            v-model="sidebarData.uuid_rkeeper"
            placeholder="Введите номер стола в R keeper"
            id="uuid_rkeeper"
            :error="$v.sidebarData.uuid_rkeeper.$error"
          />
          <template #error>
            <div v-if="$v.sidebarData.uuid_rkeeper.$dirty && !$v.sidebarData.uuid_rkeeper.required">Обязательное поле</div>
          </template>
        </app-form-group>
        <app-form-group label="Минимальная посадка" label-for="min_seat_number" required>
          <app-input
            v-model.number="sidebarData.min_seat_number"
            placeholder="Введите минимальную посадку"
            id="min_seat_number"
            :error="$v.sidebarData.min_seat_number.$error"
          />
          <template #error>
            <div v-if="$v.sidebarData.min_seat_number.$dirty && !$v.sidebarData.min_seat_number.required">Обязательное поле</div>
            <div v-if="$v.sidebarData.min_seat_number.$dirty && !$v.sidebarData.min_seat_number.numeric">Принимаются только числовые значения</div>
          </template>
        </app-form-group>
        <app-form-group label="Максимальная посадка" label-for="max_seat_number" required>
          <app-input
            v-model.number="sidebarData.max_seat_number"
            placeholder="Введите максимальную посадку"
            id="max_seat_number"
            :error="$v.sidebarData.max_seat_number.$error"
          />
          <template #error>
            <div v-if="$v.sidebarData.max_seat_number.$dirty && !$v.sidebarData.max_seat_number.required">Обязательное поле</div>
            <div v-if="$v.sidebarData.max_seat_number.$dirty && !$v.sidebarData.max_seat_number.numeric">Принимаются только числовые значения</div>
          </template>
        </app-form-group>
        <app-form-group label="Базовая продолжительность (мин)" label-for="base_duration">
          <app-input
            v-model.number="sidebarData.base_duration"
            placeholder="Введите базовую продолжительность"
            id="base_duration"
            :error="$v.sidebarData.base_duration.$error"
          />
          <template #error>
            <div v-if="$v.sidebarData.base_duration.$dirty && !$v.sidebarData.base_duration.numeric">Принимаются только числовые значения</div>
          </template>
        </app-form-group>
        <app-form-group>
          <app-checkbox
            label="Активность"
            v-if="!isAdd"
            v-model="sidebarData.active"
          />
        </app-form-group>
        <app-cells>
          <app-button v-if="!isAdd" ref="submit" :disabled="$v.sidebarData.$error">Сохранить</app-button>
          <app-button v-else ref="submit" :disabled="$v.sidebarData.$error">Добавить</app-button>
        </app-cells>
      </form>
<!--      <app-button @click="onDelete" v-if="!isAdd" theme="error" ref="delete">Удалить</app-button>-->
    </app-sidebar-right>
  </div>
</template>

<script>
import { getHalls, getTables, putTable, postTable, deleteTable } from '@/http'
import { debounce, isObject } from 'lodash'
import { required, numeric } from 'vuelidate/lib/validators'

export default {
  name: 'Tables',
  data() {
    return {
      table: {
        items: [],
        columns: ['id', 'hall.name', 'number', 'uuid_rkeeper', 'min_seat_number'],
        options: {
          headings: {
            'id': 'ID',
            'hall.name': 'Зал',
            'number': 'Номер стола',
            'uuid_rkeeper': 'Номер стола в R keeper',
            'min_seat_number': 'Вместимость',
          },
          pagination: { show: false },
          sortable: [],
          perPage: 100,
          texts: {
            noResults: 'Нет подходящих записей',
          },
          rowClassCallback: () => 'table-default__row',
          resizableColumns: false
        },
      },
      filterActive: '',
      filtersActive: [
        {
          value: '',
          label: 'Все',
        },
        {
          value: 'true',
          label: 'Активный',
        },
        {
          value: 'false',
          label: 'Неактивный',
        },
      ],
      filterHall: '',
      filtersHalls: [ { value: '', label: 'Все' } ],
      show_sidebar: false,
      sidebarData: {},
      hallsList: [],
      isAdd: false,
      input_search: ''
    }
  },
  validations: {
    sidebarData: {
      hall: { required },
      number: { required },
      uuid_rkeeper: { required },
      min_seat_number: { required, numeric },
      max_seat_number: { required, numeric },
      base_duration: { numeric },
    }
  },
  created () {
    this.fetchItems()
    getHalls().then(res => {
      this.hallsList = res.data
      res.data.map(hall => { this.filtersHalls.push({ label: hall.name, value: hall.id }) })
    })
  },
  methods: {
    fetchItems(search = this.input_search, active = this.filterActive, hall = this.filterHall) {
      getTables({ search, active, hall }).then(res => { this.table.items = res.data })
    },
    onFilterSearch(search) {
      this.searchFilter(search, this)
    },
    searchFilter: debounce((search, vm) => {
      vm.fetchItems(search)
    }, 350),
    onRowClick(element) {
      this.isAdd = false
      this.show_sidebar = true
      this.sidebarData = { ...element.row }
    },
    onCheckForm() {
      this.$v.sidebarData.$touch()
      if (this.$v.sidebarData.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание',
          text: 'Проверьте правильность заполнения полей формы'
        })
      } else {
        this.sendForm()
      }
    },
    sendForm() {
      this.$refs.submit.preload = true
      if (!this.isAdd) {
        putTable(this.sidebarData.id, this.normalizeForm())
          .then(() => {
            this.$refs.submit.preload = false
            this.show_sidebar = false
            this.fetchItems()
            this.$notify({
              type: 'success',
              title: 'Успех',
              text: 'Стол обновлен'
            })
          })
          .catch(() => { this.$refs.submit.preload = false })
      } else {
        postTable(this.normalizeForm())
          .then(() => {
            this.$refs.submit.preload = false
            this.show_sidebar = false
            this.fetchItems()
            this.$notify({
              type: 'success',
              title: 'Успех',
              text: 'Стол добавлен'
            })
          })
          .catch(() => { this.$refs.submit.preload = false })
      }
    },
    normalizeForm() {
      const normalizedForm = { ...this.sidebarData }

      if (isObject(normalizedForm.hall)) normalizedForm.hall = normalizedForm.hall.id
      return normalizedForm
    },
    onAddButtonHandler() {
      this.sidebarData = {}
      this.$v.sidebarData.$reset()
      this.isAdd = true
      this.show_sidebar = true
    },
    // onDelete() {
    //   deleteTable(this.sidebarData.id)
    //     .then(() => {
    //       this.fetchItems()
    //       this.show_sidebar = false
    //       this.$notify({
    //         type: 'success',
    //         title: 'Успех',
    //         text: 'Стол удален'
    //       })
    //     })
    //     .finally(() => {
    //       this.$refs.delete.preload = false
    //     })
    // }
  }
}
</script>
